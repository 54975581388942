import { GatsbySeo } from "gatsby-plugin-next-seo";
import React, { useEffect } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Layout from "../../components/layout";
import DelegateTable from "../../components/delegate-table";
import { useAuth0 } from "@auth0/auth0-react";
import { withAuthenticationRequired } from "@auth0/auth0-react";

const AttendeesPage = () => {
	const { user } = useAuth0();

	// <GetRole id={user.sub}/>
	// GetRoleP(user.sub);
	const roles = user["https://register.richmondevents.com/roles"];
	let nsIsEnabled = roles.includes("Admin") || roles.includes("Manager");
	function renderConfirmation(message) {
		const confirmed = window.confirm(message);
		if (confirmed) {
			window.location.href = "/admin/home"; // replace "/" with the desired home page URL
		}
	}

	if (nsIsEnabled) {
		return (
			<div>
				<GatsbySeo title="Event Attendees" language="en" noindex nofollow />

				<div style={{ background: "#F8F8F8", minHeight: "100vh" }}>
					<Layout>
						<DelegateTable filterValue="name" />
					</Layout>
				</div>
			</div>
		);
	} else {
		return (
			<div>
				<GatsbySeo title="Event Attendees" language="en" noindex nofollow />

				<div style={{ background: "#F8F8F8", minHeight: "100vh" }}>
					<Layout>
						{renderConfirmation(
							"You do not have permission to view this page. you will be redirected to the homepage."
						)}
					</Layout>
				</div>
			</div>
		);
	}
};

export default withAuthenticationRequired(AttendeesPage);
