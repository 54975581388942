// Import statements for React, React Bootstrap components, react-table functionalities, icons, and other utilities.

import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState, useEffect } from "react";

import {
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";
import Modal from "react-bootstrap/Modal";
import Airtable from "airtable";
import Filters from "./Filters";
import moment from "moment";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import DesktopLogo from "../images/mdi_sort.svg";
import { BsThreeDots } from "react-icons/bs";
import { TbSortAscending, TbSortDescending } from "react-icons/tb";
import { MdOutlineClear, MdVideoCameraFront } from "react-icons/md";
import LeftNavbar from "./left-navbar";
import AccountBox from "./account-box";
import Skeleton from "react-loading-skeleton";
import { v4 as uuidv4 } from "uuid";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import axios from "axios";

const DelegateTable = () => {
	// State hooks for managing data and UI states.
	const [data, setData] = useState([]);
	const [dataRecords, setDataRecords] = useState([]);
	const [dataRecords2, setDataRecords2] = useState([]);
	const [filtering, setFiltering] = useState("");
	const [show, setShow] = useState(false);
	const [selectedEventId, setSelectedEventId] = useState("");
	const [selectedEvent, setSelectedEvent] = useState("");

	// Functions for modal open/close actions.
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	var tempEventArray = [];
	var fullObjArray = [];
	var fullObjArray2 = [];
	const [loadForm, setLoadForm] = useState(false);
	const [disableLoad, setDisableLoad] = useState(false);
	var queryParameters = "";
	var uniqueId = "";
	var uniqueDate = "";

	useEffect(() => {
		if (typeof Window !== "undefined") {
			queryParameters = new URLSearchParams(window.location.search);
			uniqueId = queryParameters.get("code") || "";
		}
	}, []);
	useEffect(() => {
		if (disableLoad === true) {
			setTimeout(() => {
				setLoadForm(true);
			}, 500);
		}
	}, [disableLoad]);

	useEffect(() => {
		const temp = data.find((element) => element.id === selectedEventId);
		setSelectedEvent(temp);
	}, [selectedEventId]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(
					"/.netlify/functions/delegate-table-fetch",
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							"x-api-key": process.env.GATSBY_DELEGATETABLE_KEY_1, // Replace with your API key
						},
						body: JSON.stringify({ uniqueId: uniqueId }),
					}
				);

				if (!response.ok) {
					throw new Error("Network response was not ok");
				}

				const { attendeesRecords, suggestedRecords } = await response.json();
				setData(attendeesRecords.map((record) => record.fields));
				setDataRecords(attendeesRecords);
				setDataRecords2(suggestedRecords);

				setDisableLoad(true);
			} catch (err) {
				// console.error(err);
				alert(
					"Could not retrieve data from Airtable. Please wait 30 seconds and refresh the page"
				);
			}
		};

		fetchData();
	}, []);

	const columns = [
		{
			accessorKey: "view",
			header: "View",
			size: 100,
			cell: (props) => (
				<div>
					<BsThreeDots
						onClick={() => {
							setSelectedEventId(props.row.original.id);

							handleShow();
						}}
						style={{ cursor: "pointer" }}
					/>
				</div>
			),
			enableGlobalFilter: false,
			enableSorting: false,
		},
		{
			enableGlobalFilter: true,
			accessorKey: "name",
			header: "Name",
			size: 100,

			cell: (props) => (
				<p style={{ fontSize: "90%" }} className="pb-0 mb-0">
					{props.getValue()}
				</p>
			),
			enableColumnFilter: true,
			filterFn: "includesString",
		},

		{
			enableGlobalFilter: true,
			accessorKey: "confirmDate",
			header: "Confirmation Date",
			cell: (props) => (
				<p style={{ fontSize: "90%" }} className="pb-0 mb-0">
					{props.getValue() || "N/A"}
				</p>
			),
		},
		{
			enableGlobalFilter: true,
			accessorKey: "companyName",
			header: "Company",
			cell: (props) => (
				<p style={{ fontSize: "90%" }} className="pb-0 mb-0">
					{props.getValue()}
				</p>
			),
		},
		{
			enableGlobalFilter: true,
			accessorKey: "jobTitle",
			header: "Position",
			cell: (props) => (
				<p style={{ fontSize: "90%" }} className="pb-0 mb-0">
					{props.getValue()}
				</p>
			),
		},
		{
			enableGlobalFilter: true,
			accessorKey: "status",
			header: "Status",
			cell: (props) => (
				<div className="text-center">
					{props.getValue() === "Confirmed" && (
						<div
							className="d-inline-block y-1 px-3"
							style={{ borderRadius: "100px", background: "#A9EFA7" }}
						>
							<p style={{ fontSize: "90%" }} className="pb-0 mb-0">
								{props.getValue()}
							</p>
						</div>
					)}
					{props.getValue() === "Abandoned" && (
						<div
							className="d-inline-block   px-3"
							style={{ borderRadius: "100px", background: "#EFB9A7" }}
						>
							<p style={{ fontSize: "90%" }} className="pb-0 mb-0">
								{props.getValue()}
							</p>
						</div>
					)}
				</div>
			),
		},
	];

	const table = useReactTable({
		data,
		columns,
		state: {
			globalFilter: filtering,
		},
		onGlobalFilterChange: setFiltering,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
		columnResizeMode: "onChange",
		meta: {
			updateData: (rowIndex, columnId, value) =>
				setData((prev) =>
					prev.map((row, index) =>
						index === rowIndex
							? {
									...prev[rowIndex],
									[columnId]: value,
							  }
							: row
					)
				),

			deleteData: (code) =>
				setData((prev) =>
					prev
						.filter((item, index) => item.code !== code)
						.map((row, index) => row)
				),
		},
	});

	const renderSortIcon = (column) => {
		const isSorted = column.getIsSorted();
		const canSort = column.getCanSort();

		if (!canSort) {
			return null;
		}

		if (!isSorted) {
			return (
				<img
					src={DesktopLogo}
					alt="Sort"
					style={{ width: "15px", cursor: "pointer" }}
					onClick={column.getToggleSortingHandler()}
					className="ms-2"
				/>
			);
		}

		if (isSorted === "asc") {
			return (
				<TbSortAscending
					onClick={column.getToggleSortingHandler()}
					style={{ width: "15px", cursor: "pointer", height: "15px" }}
					className="ms-2"
				/>
			);
		}

		if (isSorted === "desc") {
			return (
				<TbSortDescending
					onClick={column.getToggleSortingHandler()}
					style={{ width: "15px", cursor: "pointer", height: "15px" }}
					className="ms-2"
				/>
			);
		}

		return null;
	};

	const renderTableHeader = () => {
		return table.getHeaderGroups().map((headerGroup) => (
			<thead key={headerGroup.id}>
				<tr>
					{headerGroup.headers.map((header) => (
						<th
							key={header.id}
							className="text-center pt-3 fw-bold pb-3"
							style={{
								width: header.getSize(),
								backgroundColor: "#FBFBFB",
							}}
						>
							{header.column.columnDef.header}
							{renderSortIcon(header.column)}
						</th>
					))}
				</tr>
			</thead>
		));
	};

	const renderTableBody = () => {
		return table.getRowModel().rows.map((row) => (
			<tr key={row.id} className="text-center">
				{row.getVisibleCells().map((cell) => (
					<td
						key={cell.id}
						className="py-3"
						style={{ width: cell.column.getSize() }}
					>
						{flexRender(cell.column.columnDef.cell, cell.getContext())}
					</td>
				))}
			</tr>
		));
	};

	return (
		<section>
			<Modal
				style={{ zIndex: 99999 }}
				size="xl"
				show={show}
				onHide={handleClose}
				centered
			>
				<Modal.Body
					style={{ borderRadius: "8px" }}
					className="bg-white px-4"
					closeButton
				>
					<div className="   position-relative">
						<div className="position-absolute  end-0 top-0  pt-2">
							<MdOutlineClear
								style={{ cursor: "pointer" }}
								className="fs-4 text-black"
								onClick={handleClose}
							/>
						</div>
						<div className="pb-5 pt-3">
							<h2 className="fs-4">Delegate details</h2>
							<hr />
							<Row>
								<Col lg={6}>
									<Row>
										<Col xs={4}>
											<h3 className="fs-6 text-med-grey">Title</h3>
											<p className="text-black">{selectedEvent?.title}</p>
										</Col>
										<Col xs={4}>
											<h3 className="fs-6 text-med-grey">First name</h3>
											<p className="text-black">{selectedEvent?.firstName}</p>
										</Col>
										<Col xs={4}>
											<h3 className="fs-6 text-med-grey">Last name</h3>
											<p className="text-black">{selectedEvent?.lastName}</p>
										</Col>
									</Row>
									<Row>
										<Col>
											<h3 className="fs-6 text-med-grey mt-3">
												Street name and number
											</h3>
											<p className="text-black">
												{selectedEvent?.address1}
												{selectedEvent?.address2 &&
													`, ${selectedEvent?.address2}`}
												{selectedEvent?.address3 &&
													`, ${selectedEvent?.address3}`}
											</p>
										</Col>
									</Row>
									<Row>
										<Col>
											<h3 className="fs-6 text-med-grey mt-3">Country</h3>
											<p className="text-black">{selectedEvent?.country}</p>
										</Col>
									</Row>
									<Row>
										<Col xs={6}>
											<h3 className="fs-6 text-med-grey mt-3">
												Business phone
											</h3>
											<p className="text-black">
												{selectedEvent?.businessPhone}
											</p>
										</Col>
										<Col xs={6}>
											<h3 className="fs-6 text-med-grey mt-3">
												Business email
											</h3>
											<p className="text-black">
												{selectedEvent?.businessEmail}
											</p>
										</Col>
									</Row>
								</Col>
								<Col lg={6}>
									<Row>
										<Col xs={6}>
											<h3 className="fs-6 text-med-grey">Job title</h3>
											<p className="text-black">{selectedEvent?.jobTitle}</p>
										</Col>
										<Col xs={6}>
											<h3 className="fs-6 text-med-grey ">Company name</h3>
											<p className="text-black">{selectedEvent?.companyName}</p>
										</Col>
									</Row>
									<Row>
										<Col xs={6}>
											<h3 className="fs-6 text-med-grey mt-3">City</h3>
											<p className="text-black">{selectedEvent?.city}</p>
										</Col>
										<Col xs={6}>
											<h3 className="fs-6 text-med-grey mt-3">
												Postcode/Zipcode
											</h3>
											<p className="text-black">
												{selectedEvent?.postcodeZipcode}
											</p>
										</Col>
									</Row>
									<Row>
										<Col xs={6}>
											<h3 className="fs-6 text-med-grey mt-3">Mobile</h3>
											<p className="text-black">{selectedEvent?.mobile}</p>
										</Col>
										<Col xs={6}>
											<h3 className="fs-6 text-med-grey mt-3">
												Alternate email
											</h3>
											<p className="text-black">
												{selectedEvent?.alternateEmail}
											</p>
										</Col>
									</Row>
								</Col>
							</Row>
							{dataRecords2.filter(
								(item, index) => item.fields.refId === selectedEvent?.id
							).length > 0 && (
								<div>
									<Row>
										<Col>
											<h2 className="fs-4 mt-4">Suggested attendees</h2>
											<hr />
										</Col>
									</Row>
									{dataRecords2
										.filter(
											(item, index) => item.fields.refId === selectedEvent?.id
										)
										.map((item) => {
											return (
												<Row className="mb-4" key={item.id}>
													<Col xs={true}>
														<h3 className="fs-6 text-med-grey">Name</h3>
														<p className="text-black">{item.fields?.name}</p>
													</Col>
													<Col xs={true}>
														<h3 className="fs-6 text-med-grey">Event role</h3>
														<p className="text-black">
															{item.fields?.eventRole}
														</p>
													</Col>
													<Col xs={true}>
														<h3 className="fs-6 text-med-grey">Email</h3>
														<p className="text-black">{item.fields?.email}</p>
													</Col>
													<Col xs={true}>
														<h3 className="fs-6 text-med-grey">Job title</h3>
														<p className="text-black">
															{item.fields?.jobTitle}
														</p>
													</Col>
													<Col xs={true}>
														<h3 className="fs-6 text-med-grey">Mobile</h3>
														<p className="text-black">{item.fields?.mobile}</p>
													</Col>
												</Row>
											);
										})}
								</div>
							)}
						</div>
					</div>
				</Modal.Body>
			</Modal>
			<Container className="px-4 pb-5 pt-3 pb-lg-7" fluid>
				<Row className="justify-content-end mb-4">
					<Col lg={2}>
						<AccountBox />
					</Col>
				</Row>
				<Row>
					<Col style={{ minHeight: "100%" }} lg={1}>
						<LeftNavbar />
					</Col>
					<Col lg={11}>
						<div
							style={{ borderRadius: "10px", minHeight: "400px" }}
							className="w-100 bg-white"
						>
							<Row className="justify-content-between pb-3 pt-4 ps-4 ms-1 pe-6">
								<Col>
									<h2 className="fs-5">Attendees/Suggestions</h2>
								</Col>
								<Col className="text-end">
									<Filters
										columnFilters={filtering}
										setColumnFilters={setFiltering}
									/>
								</Col>
							</Row>

							<Table hover>
								{renderTableHeader()}
								{loadForm && <tbody>{renderTableBody()}</tbody>}
								{!loadForm && (
									<tbody>
										<tr className="text-center">
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
										</tr>
										<tr className="text-center">
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
										</tr>
										<tr className="text-center">
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
										</tr>
										<tr className="text-center">
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
										</tr>
										<tr className="text-center">
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
											<td key={uuidv4()} className="py-3">
												<div className=" w-100">
													<Skeleton className="py-3" />
												</div>
											</td>
										</tr>
									</tbody>
								)}
							</Table>
							<Row>
								<Col className="pe-4" lg={12}>
									<Row className="py-4 align-items-center">
										<Col className="text-end" lg={11}>
											<p className="pb-0 mb-0 fw-bold">
												Page {table.getState().pagination.pageIndex + 1} of{" "}
												{table.getPageCount()}
											</p>
										</Col>
										<Col className="" lg={1}>
											<ButtonGroup aria-label="Basic example">
												<Button
													style={{ ountline: "none", border: "none" }}
													variant="outline-black"
													onClick={() => table.previousPage()}
													disabled={!table.getCanPreviousPage()}
													className="mx-0 ps-0"
												>
													<IoIosArrowBack className="fs-2" />
												</Button>
												<Button
													className="mx-0 ps-0 "
													style={{ ountline: "none", border: "none" }}
													variant="outline-black"
													onClick={() => table.nextPage()}
													disabled={!table.getCanNextPage()}
												>
													<IoIosArrowForward className="fs-2" />
												</Button>
											</ButtonGroup>
										</Col>
									</Row>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};
export default DelegateTable;
